@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Rakkas&display=swap");

body {
  font-family: "Jost", sans-serif;
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

/* Custom loader */
.loader {
  border: 18px solid #ffffff;
  border-top: 18px solid rgb(197, 161, 99);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 350ms linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rc-pagination.rc-pagination-center {
  @apply text-lg;
}

.tick-button::before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 9px;
  margin-right: 9px;
  margin-bottom: 3px;
  border-bottom: 3px solid green;
  border-left: 3px solid green;
  transform: rotate(-45deg);
}

.rc-pagination-next button::after,
.rc-pagination-prev button::after {
  font-size: 30px;
  font-weight: 500;
  top: -3.3px;
  position: relative;
}

input::-ms-reveal {
  display: none;
}
